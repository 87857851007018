@use '/src/app/styles/variables' as *;

.main {
  padding: 48px 0px 15px 15px;
  height: calc(100% - 122px);
  overflow-y: scroll;

  .title {
    position: fixed;
    background: $white;
    width: calc(100% - 30px);
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 15px;

    z-index: 1;

    &__transparent {
      background: none;
    }

    &__visible {
      top: 54px;
      transition: top 0.4s ease-out;
    }

    &__hidden {
      top: 4px;
      transition: top 0.4s ease-out;
    }
  }

  .outletWrapper {
    padding: 0 15px;
  }
}
