@use '/src/app/styles/variables' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: linear-gradient($white, $white) padding-box,
    linear-gradient(to right, $aqua, $aqua1, $green1) border-box;
  border: 2px solid transparent;

  margin-bottom: 15px;

  .row:first-child {
    margin-top: 5px;
  }

  .row {
    margin-bottom: 13px;
  }

  >img {
    display: block;
    border-radius: 9px 9px 0 0;
  }

  .content {
    padding: 10px;
  }

  .routeIcon {
    margin-right: 10px;
  }

  .titleArrowIcon {
    margin: 0 10px;
    color: $green2;
  }

  .feedDate {
    margin: 4px 0 0 10px;
    font-size: 16px;
  }

  .feedCaption {
    padding: 4px 0 0 10px;
    font-size: 18px;

    :global(.ant-typography) {
      font-size: 18px;
    }

    .driversLink {
      color: $black1;
      margin-left: 7px;
      border-bottom: 1px solid $black1;
    }
  }

  .description {
    color: $black2;
    font-size: 16px;
    font-weight: 400;
  }

  .separatorLine {
    height: 1px;
    background: linear-gradient(to right, $aqua, $aqua1, $green1);
    margin-bottom: 10px;
  }

  .price {
    font-size: 20px;
    font-weight: 500;
    padding: 4px 0 0 10px;
  }

  .openButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    width: 40%;
    background-color: $green2;
    border-radius: 8px;
    color: $black;
    font-size: 14px;
    font-weight: 500;
    box-shadow: 0px 13px 13px 0px rgba(0, 0, 0, 0.09);
  }
}
