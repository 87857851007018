@use '/src/app/styles/fonts' as *;

* {
  font-family: Geometria, sans-serif !important;
}

body {
  line-height: initial;
  overflow: hidden;

  .ant-typography {
    margin: 0 !important;
  }
  h1 {
    font-size: 24px !important;
    font-weight: 500 !important;
  }
  h2 {
    font-size: 20px !important;
    font-weight: 500 !important;
  }
}

#root {
  height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow: hidden;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 15px;
}
::-webkit-scrollbar:horizontal {
  height: 15px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .1);
  border-radius: 10px;
  border: 2px solid #ffffff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
