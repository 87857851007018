/* Colors */
$transparent: transparent;
$white: #fff;
$gray: #ebedf5;
$black: #000000;
$black1: #002138;
$black2: #464646;

$turquoise: #00B5AC;
$aqua: #25E3D9;
$aqua1: #2AE2AC;

$green1: #31DC58;
$green2: #32DD59;
