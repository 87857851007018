@use '/src/app/styles/variables' as *;

.wrapper {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: $black1;
  border-radius: 0px 0px 15px 15px;

  position: relative;
  z-index: 2;

  padding: 0 30px;

  .navLink {
    margin-top: 3px;
  }

  .logoIcon {
    display: block;
    height: 50px;
    width: 50px;
  }

  .userName {
    display: flex;
    align-items: center;
    color: $white;

    >span {
      display: block;
      color: $white;
      font-size: 16px;
      margin: 3px 0 0 9px !important;
    }
  }
}
