@use '/src/app/styles/variables' as *;

.wrapper {
  position: fixed;
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  height: 68px;
  border-radius: 15px 15px 0px 0px;
  background: $black1;

  z-index: 2;
}
