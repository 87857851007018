@use '/src/app/styles/variables' as *;

.showMoreButton {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: $black1 !important;
  padding: 21px 0 93px 0;

  span {
    border-bottom: 1px solid $black1;
    height: 22px;
  }
}

.addRouteButtonWrapper {
  width: 100%;
  position: fixed;
  left: 0px;
  padding: 0 27px;

  z-index: 1;

  &__visible {
    bottom: 68px;
    transition: bottom 0.4s ease-out;
  }

  &__hidden {
    bottom: -2px;
    transition: bottom 0.4s ease-out;
  }
}

.addRouteButton {
  width: 100%;

  @media (min-width:801px)  {
    width: 60%;
  }

  background: $turquoise !important;
  color: $black1 !important;
  border: none !important;
  border-radius: 6px 6px 0 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  padding: 35px;
}
