@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 400;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-Italic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 100;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 100;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-ThinItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 200;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-ExtraLight.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 200;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-ExtraLightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 300;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-Light.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 300;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-LightItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 500;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-MediumItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 700;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 700;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-BoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 800;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-ExtraBold.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 800;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-ExtraBoldItalic.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 900;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-Heavy.woff') format('woff');
}
@font-face {
  font-family: 'Geometria';
  font-style: italic;
  font-weight: 900;
  src: local('Geometria'), url('../../shared/assets/fonts/Geometria-HeavyItalic.woff') format('woff');
}
